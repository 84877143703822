<template>
    <v-card class="elevation-0">
        <!--<v-card-text class="page-title d-block d-lg-none pt-2 pb-0 pr-0">{{ $route.meta.name }}</v-card-text>-->
        <!--<v-breadcrumbs class="pb-0 mb-0 pt-3 mr-0 pr-0" :items="items"></v-breadcrumbs>-->
        <div class="main-container" v-if="sessions.length">
            <v-row justify="right">
                <v-col cols="12" sm="6" md="4" lg="4" v-for="(session,index) in sessions"
                       :key="index">
                    <SessionCard :session="session" @updateSessions="getAwaitingSessions"></SessionCard>
                </v-col>
            </v-row>
            <div class="text-center">
                <v-pagination
                        v-model="page"
                        :length="total"
                ></v-pagination>
            </div>
        </div>
        <v-card-text class="text-center" v-else>
          {{$t('$vuetify.sessionsPage.noSession')}}
        </v-card-text>
    </v-card>
</template>


<script>
    import SessionCard from '@/components/sessions/SessionCard';
    import {EventBus} from "@/event-bus";

    export default {
        created() {
            this.getAwaitingSessions();
        },
        data() {
            return {
                sessions: [],
                total: 1,
                page: 1,
                update: false,
                items: [
                    {
                        text: 'داشبورد',
                        disabled: false,
                        to: '/fa/panel/dashboard',
                    },
                    {
                        text: 'جلسات در انتظار تایید',
                        disabled: true,
                        to: '/fa/panel/sessions',
                    }
                ]
            }
        },
        methods: {
            getAwaitingSessions() {
                this.sessions = [];
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('requestedSessions', {page: this.page}).then((data) => {

                    this.total = data.data.pagination.total_pages;
                    this.sessions = data.data.sessions;
                    // this.total = data.paginate.totalPages
                    this.$emit('updated');
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                });
            },
            updateSessions() {
                this.update = true;
            },
        },
        components: {
            SessionCard
        },
        watch: {
            update() {
                this.getAwaitingSessions();
            },
            page() {
                this.getAwaitingSessions();
            }
        }

    }
</script>

<style scoped>

</style>