<template>
  <div>
    <v-card-text class="page-title d-block d-lg-none pt-2 pb-0 pr-0">{{ $route.meta.name }}</v-card-text>
    <v-breadcrumbs class="pb-0 mb-0 pt-3 mr-0 pr-0" :items="items"></v-breadcrumbs>
    <v-card-text v-if="sessions.length" class="mt-0 pt-1">
      <v-row justify="right">
        <v-col cols="12" sm="6" md="6" lg="4" v-for="(session,index) in sessions" :key="index">
          <!--<SessionCard :session="session"></SessionCard>-->
          <!--{{}}-->
          <SessionCard :mode="'doctor'" :factor="false" :session="session"></SessionCard>
        </v-col>
      </v-row>

      <div class="text-center">
        <v-pagination
            v-model="page"
            :length="total"
        ></v-pagination>
      </div>
    </v-card-text>
    <v-card-text class="text-center" v-else>
      {{this.$t('$vuetify.sessionsPage.noSession')}}
    </v-card-text>
  </div>
</template>


<script>
// import ClientSessions from '@/components/clientComponents/ClientSessionsComponent';
// import ClientSessionRequests from '@/components/clientComponents/ClientSessionsRequests';
import SessionCard from '@/components/sessions/SessionCard';
import {EventBus} from "@/event-bus";


export default {
  created() {
    this.getCounselorSessions();
    // this.getAwaitingSessions();
  },
  data() {
    return {
      awaitingSessions: [],
      awaitingTotal: 1,
      awaitingPage: 1,
      page: 1,
      total: 1,
      model: 'tab-1',
      update: false,
      sessions: [],
      items: [
        {
          text: this.$t('$vuetify.dashboard'),
          disabled: false,
          to: `/${this.$vuetify.rtl ? "fa" : "en"}/panel/dashboard`,
        },
        {
          text: this.$t('$vuetify.navSessionsLabel'),
          disabled: true,
          to: `/${this.$vuetify.rtl ? "fa" : "en"}/panel/sessions`,
        }
      ]
    }
  },
  methods: {
    getAwaitingSessions(loading = false) {
      if (loading) EventBus.$emit('setLoading', true);
      this.$store.dispatch('requestedSessions', {page: this.awaitingPage}).then((data) => {

        this.awaitingTotal = data.data.pagination.total_pages;
        this.awaitingSessions = data.data.sessions;
        // this.total = data.paginate.totalPages
        this.$emit('updated');
      }).finally(() => {
        if (loading) EventBus.$emit('setLoading', false);
      });
    },
    updateSessions() {
      this.update = true;
    },
    getCounselorSessions() {
      // let options = {
      //     state: 'date_fixed|used',
      //     orderBy: "session_date:desc"
      // };
      //
      // if (this.activeCycle != null) {
      //     options.cycle_id = this.activeCycle.id;
      // }

      let options = {
        "orderBy": "session_date:desc",
        'state': 'date_fixed|used'
      };

      EventBus.$emit('setLoading', true);
      this.$store.dispatch('getCounselorSessions', {page: this.page, options}).then((resp) => {
        this.total = resp.data.pagination.total_pages;
        this.sessions = resp.data.sessions;
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      })

      // return;
      // this.$store.dispatch('getClientAllSessions', {page: this.page}).then((data) => {
      //     this.total = data.data.pagination.total_pages;
      //     this.sessions = data.data.sessions;
      //     // this.total = data.paginate.totalPages
      //     this.$emit('updated');
      // }).finally(() => {
      //     EventBus.$emit('setLoading', false);
      // });
    },
  },
  components: {
    SessionCard

    // ClientSessions,
    // ClientSessionRequests
  },
  watch: {
    update() {
      this.getCounselorSessions();
    },
    page() {
      this.getCounselorSessions();
      this.$vuetify.goTo(this.$refs.sessions.offsetTop);

    },
    awaitingPage() {
      this.getAwaitingSessions();

    }
  }

}
</script>

<style scoped>

</style>